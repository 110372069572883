.form-input {
  padding: 0.5em 0.75em;
  border: none;
  border-bottom: 1px solid #000;
  background-color: #f6f6f6;
  width: 100%;
}

.form-sub-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-section-title {
  font-size: 1.6em;
  font-weight: bold;
  padding: 0.7rem 1.4rem;
  border-bottom: 1px solid #ddd;
}

.form-section-content {
  padding: 0 1.4rem;
}

.form-toggle {
  position: relative;
  display: inline-block;
  width: 2.5em;
  height: 1.7em;
}

.form-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.form-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0.6em;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f4f4f4;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 0;
}

.form-toggle-slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  left: -2px;
  bottom: -2px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 0;
}

.form-toggle-sub-text {
  font-size: 0.8em;
  color: #aaa;
}

input:checked + .form-toggle-slider {
  background-color: #FE2C7D;
}

input:checked + .form-toggle-slider:before {
  -webkit-transform: translateX(1.4em);
  -ms-transform: translateX(1.4em);
  transform: translateX(1.4em);
}

.form-secondary-button {
  padding: 0.6em;
  font-weight: bold;
  font-size: 1.1em;
  border: none;
  border-radius: 0;
  margin-bottom: 2em;
  color: #000;
  background-color: #fff;
}

.form-submit-button {
  width: 100%;
  padding: 0.6em;
  font-weight: bold;
  font-size: 1.1em;
  border: none;
  border-radius: 0;
  background-color: #e0e0e0;
  margin-bottom: 2em;
}

.form-input-invalid {
  border-bottom: 1px solid #fa755a;
}

.form-error-message {
  color: #ff0000;
}
