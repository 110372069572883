.resturant-reviews-image-browse-button {
  border: 1px solid #000;
  background: none;
  padding: 0.4rem 1.9rem;
}

.restaurant-reviews-image-dropzone {
  height: 100%;
  min-height: 20rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
}

.restaurant-reviews-image-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ngx-file-drop__drop-zone-label {
  text-align: center;
  margin-bottom: 1rem;
  line-height: 2rem;
}

.ngx-file-drop__drop-zone-label::after {
  content: 'or';
  display: block;
}
