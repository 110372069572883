.loading-gradient {
  background: rgba(255, 0, 89, 1);
  background: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(225, 225, 225, 1) 33%, rgba(255, 255, 255, 1) 66%, rgba(225, 225, 225, 1) 100%);
  background: -moz-linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(225, 225, 225, 1) 33%, rgba(255, 255, 255, 1) 66%, rgba(225, 225, 225, 1) 100%);
  background: -ms-linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(225, 225, 225, 1) 33%, rgba(255, 255, 255, 1) 66%, rgba(225, 225, 225, 1) 100%);
  background: -o-linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(225, 225, 225, 1) 33%, rgba(255, 255, 255, 1) 66%, rgba(225, 225, 225, 1) 100%);
  background: linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(225, 225, 225, 1) 33%, rgba(255, 255, 255, 1) 66%, rgba(225, 225, 225, 1) 100%);
  background-size: 400% 400%;
  -webkit-animation: loading-gradient-animation 5s linear infinite;
  -moz-animation: loading-gradient-animation 5s linear infinite;
  animation: loading-gradient-animation 5s linear infinite;
}

@-webkit-keyframes loading-gradient-animation {
  0% {
      background-position: 100% 0%
  }
  100% {
      background-position: 15% 100%
  }
}

@-moz-keyframes loading-gradient-animation {
  0% {
      background-position: 100% 0%
  }
  100% {
      background-position: 15% 100%
  }
}

@keyframes loading-gradient-animation {
  0% {
      background-position: 100% 0%
  }
  100% {
      background-position: 15% 100%
  }
}
