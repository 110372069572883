/* You can add global styles to this file, and also import other style files */

@import url("https://use.typekit.net/yxk6kbz.css");
@import url("./app/shared/styles/form.css");
@import url("./app/shared/styles/ngx-file-drop.css");
@import url("./app/shared/styles/loading.css");
@import url("~@ng-select/ng-select/themes/default.theme.css");

:root {
  /* Radish Colors */
  --clr-radish-pink: #de3168;
  --clr-radish-green: #009468;
  --clr-radish-mint: #e6f4f0;

  --gift-box-border-radius: 1rem;
  /* TODO: Set inside corner radius to the outside corner radius, minus the distance between the two. */
  /* --gift-box-border-radius-inner: ; */

  /* TODO: fix & apply these! */
  --gift-box-clr-white: #FFFFFF;
  --gift-box-clr-text-primary: #000102;
  --gift-box-clr-text-secondary: #E4E1E3;
  --gift-box-clr-accent-red: #E9200A;
  --gift-box-clr-accent-1: #FF5C5A;
  --gift-box-clr-accent-2: #FF8C90;
  --gift-box-clr-accent-3: #FFCEC8;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

button:active {
  opacity: 0.95;
}

@font-face {
  font-family: "M4";
  src: url("./assets/fonts/m4_burritos-webfont.woff") format("truetype");
}

@font-face {
  font-family: "Tintin";
  src: url("./assets/fonts/tintin.woff") format("truetype");
}

@font-face {
  font-family: "CCMeanwhile";
  src: url("./assets/fonts/CCMeanwhile.woff") format("truetype");
}

@font-face {
  font-family: "Roboto Slab";
  src: url("./assets/fonts/RobotoSlab-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Syne";
  src: url("./assets/fonts/Syne-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Tangerine";
  src: url("./assets/fonts/Tangerine-Bold.ttf") format("truetype");
}

/* Changed ngbootstrap styles here because it doesn't work in the component's style sheet */

.restaurant-menu-tooltip>.tooltip-inner {
  background-color: transparent
}

.restaurant-menu-tooltip {
  transform: translate(99px, -170px) !important;
}


.restaurant-menu-tooltip.show {
  opacity: 1;
}

.restaurant-menu-tooltip>.arrow {
  left: calc(14rem / 2) !important
    /* width of tooltip divided by 2 */
}

.modal-content {
  border-radius: 0;
  border: none;
}

/* End bootstrap styles */

.page-header {
  font-size: 2.7rem;
  font-weight: bold;
}

/*
* Google Autocomplete
*/
.pac-container {
  z-index: 1100;
}


.invisible {
  visibility: hidden;
}

.radish-tooltip .tooltip-inner {
  /* border-radius: 0 !important; */
  border-radius: 6px
}

.ngx-gallery {
  display: inline-block;
  margin-bottom: 20px;
}


/* Radish Modal */

/* NgbModal Overrides */
/* ::ng-deep .modal {
    font-family: 'mr-eaves-xl-modern', Helvetica, Arial, sans-serif; 
    z-index: 9999;
}

::ng-deep .modal-content {
    border: 1px solid #eee;
    border-radius: 12px;
} */

/* .radish-modal {
} */

.radish-modal-content {
    padding: 0.5rem 1.4rem 1.8rem 1.4rem;
    position: relative;
    /* for dynamic font sizing based on container width or height */
    /* container-type: inline-size; */
}

button {
    border: none;
    outline: none;
    background-color: transparent;
}

button.radish-modal-dismiss-btn {
    /* width: 100%; */
    margin-left: auto;
}

/* i.dismiss-btn-icon {
    width: 1rem;
    height: 1rem;
    color: #191919;
} */

img.dismiss-btn-icon {
    height: 2rem;
    width: 2rem;
    position: absolute;
    top: 1rem;
    right: 1.4rem;
    padding: 0.5rem;
}

h2.radish-modal-title {
    font-size: 2rem;
    line-height: 1;
    /* font-size: clamp(1.2rem, 9cqw, 2.3rem); */
    font-weight: 700;
    margin-bottom: 1rem;
}

h3.radish-modal-subtitle {
    /* font-size: clamp(1rem, 5cqw, 1.4rem); */
    margin-bottom: 1rem;
}

.radish-modal-body {
    font-size: 1.2rem;
    /* font-size: clamp(0.9rem, 5cqw, 1.2rem); */
    line-height: 1.2;
    margin-bottom: 1rem;
}

p.radish-modal-body-text {
    /* font-size: clamp(0.9rem, 5cqw, 1.2rem); */
    line-height: 1.4;
}

.radish-modal-image-container {
    border-radius: 8px;
    background-color: #fff5f4;
    width: 100%;
    height: 12rem;
    margin: 0.8rem 0;
}

img.radish-modal-image {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

button.radish-modal-btn-primary {
    border-radius: 6px;
    background-color: #000;
    color: #fff;
    font-size: clamp(1rem, 5cqw, 1.25rem);
    font-weight: 600;
    width: 100%;
    height: 3.4rem;
    padding: 0 2rem;
    margin-left: auto;
}

.radish-modal hr {
    margin: 0.4rem 0 0.6rem 0;
}

p.radish-invalid-feedback {
  color: var(--clr-radish-pink);
  font-size: 0.98rem;
}

p.radish-valid-feedback {
  color: var(--clr-radish-green);
  font-size: 0.98rem;
}

/* Crate Pickup Scheduler Custom Styles (ng-select) */

/* TODO: rename custom class */
.ng-select.custom {
  width: 100%;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

.ng-select.custom .ng-select-container {
  border-radius: 6px;
  border-color: #e6e6e6;
  min-height: fit-content;
  height: 43px;
  font-size: 0.9rem;
}

.ng-select.custom .ng-placeholder {
  font-size: 0.9rem;
  font-weight: 400;
}

/* hide x icon */
.ng-select.custom .ng-clear-wrapper {
  display: none;
}

.ng-option-label {
  /* font-size: 0.9rem; */
}

.ng-select.custom.ng-select-single .ng-select-container .ng-value-container,
.ng-select.custom.ng-select-single .ng-select-container .ng-value-container .ng-value,
.ng-select.custom.ng-select-opened.ng-select-bottom>.ng-select-container {
  font-weight: 700;
}

.ng-select.custom .ng-dropdown-panel.ng-select-bottom {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-color: #e6e6e6;
  border-top-color: #cccccd;
}

.ng-select.custom .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.ng-select.custom .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-select.custom .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #333;
  background-color: #d6eae4;
}

.ng-select.custom .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f6fcfa;
}


/* Crate Delivery Scheduler ng-select */

/* TODO: fix container overflow on small screens */
.ng-select.delivery-time-select {
  width: 100%;
}

.ng-select.delivery-time-select .ng-select-container {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #d2d2d3;
  min-height: fit-content;
  max-height: 25px;
  box-shadow: none;
  cursor: pointer;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-radius: 3px;
  border-bottom-color: transparent;
}

.ng-select.delivery-time-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0;
  padding-left: 0;
}

.ng-select.delivery-time-select .ng-clear-wrapper {
  display: none;
}

.ng-select.delivery-time-select .ng-value-container .ng-placeholder {
  color: #000;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ng-select.delivery-time-select .ng-value-container .ng-placeholder::after {
  content: '  *';
  color: #E13A1E;
}

.ng-select.delivery-time-select .ng-select-container .ng-value-container {
  padding-left: 0;
}

.ng-select.delivery-time-select .ng-arrow-wrapper .ng-arrow {
  border-color: #21252a transparent transparent;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
  border-color: transparent transparent #21252a;
}

.ng-select.delivery-time-select .ng-dropdown-panel {
  box-shadow: none;
}

.ng-select.delivery-time-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.delivery-time-select.ng-select-single .ng-select-container .ng-value-container .ng-value,
.ng-select.delivery-time-select.ng-select-opened.ng-select-bottom>.ng-select-container {
  font-weight: 700;
}

.ng-select.delivery-time-select .ng-dropdown-panel.ng-select-bottom {
  border-radius: 0 0 6px 6px;
  border-color: #e6e6e6;
}

.ng-select.delivery-time-select .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.ng-select.delivery-time-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-select.delivery-time-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #333;
  background-color: #d6eae4;
}

.ng-select.delivery-time-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f6fcfa;
}

.ng-select.delivery-time-select .ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

/* Animations */

/* TODO: change gradient */
.shimmer {
    animation: shimmer 4s infinite;
    background: linear-gradient(to right, #F5E0E7 2%, #DCEDFA 30%, #F5E0E7 75%);
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}